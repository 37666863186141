import { Typography } from "@mui/material";
import React from "react";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { Check } from "@mui/icons-material";
import { IOnboardingItemCard } from "../../../../interfaces/onboarding/IOnboardingItemCard";
import useImageSize from "../../../../hooks/global/useImageSize";

export const OnboardingItemCard = ({
  title,
  image,
  selected,
  onClick,
  disableClick = false,
}: IOnboardingItemCard) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const [resizedImage] = useImageSize({
    imageUrl: image,
    originalFormat: true,
    size: 300,
    isCover: true,
  });

  return (
    <div className="cursor-pointer space-y-2 rounded-[10px]">
      <div onClick={disableClick ? null : onClick} className="rounded-[10px]">
        <div
          style={{
            border: "2px solid",
            borderColor: selected
              ? componentColors.primary.primary
              : "transparent",
          }}
          className="h-32 w-full relative rounded-[10px]"
        >
          <img
            src={resizedImage}
            alt={`${title} | POD1UM`}
            className="w-full h-full rounded-[10px] object-cover"
          />

          {selected && (
            <div
              className="absolute w-[32px] h-[22px] -top-[0.1rem] -right-[0.1rem]  rounded-tr-[10px] rounded-bl-[24px] "
              style={{
                backgroundColor: componentColors.primary.primary,
              }}
            >
              <Check
                sx={{
                  color: componentColors.primary.onPrimary,
                }}
                className="w-[16px] h-[16px] ml-[12px] mt-[2px] mb-[4px]"
              />
            </div>
          )}
        </div>
      </div>

      <Typography
        className="font-[500] "
        sx={{
          color: selected
            ? componentColors.primary.primary
            : componentColors.surface.onSurface,
        }}
      >
        {title}
      </Typography>
    </div>
  );
};
